$primary-color: #936447;

.item-card {
  width: 173px;
  position: relative;
  vertical-align: top;
  z-index: 1;
  overflow: hidden;
  margin: 0 0 $padding;
  text-decoration: none;
  border-radius: 7px;
  box-shadow:
    0 17px 34px 0 rgba(44, 39, 56, 0.04),
    0 8px 17px 0 rgba(44, 39, 56, 0.02);
  background-color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .residences-page & {
    margin-bottom: 10px;
  }

  &_residence {
    padding-bottom: 6px;

    &-preview {
      &-cover {
        width: 100%;
        height: 147px;
        background-size: cover;
        background-position: center center;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }

    &-price {
      display: inline-block;
      padding: 5px;
      font-weight: bold;
      font-size: 15px;
      line-height: 1.3;
      color: #2f1f19;
      z-index: 3;
      white-space: nowrap;

      span:first-child {
        margin-right: 5px;
      }
    }

    .title_block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 2px 15px 2px 5px;

      .abs_block {
        position: relative;
      }
    }

    &-title {
      padding: 0 0 0 5px;
      max-height: 33.6px;
      font-size: 14px;
      line-height: 1.21;
      // stylelint-disable-next-line
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      color: #2f1f19;
      margin: 0;
      font-weight: 500;
    }

    & &-params {
      font-size: 14px;
      line-height: 16px;
      padding: 0;
      margin: 0 15px 0 7px;
      bottom: 0;
      left: 0;
      right: 0;
      color: #2f1f19;

      > div {
        @include ellipsis;
        position: relative;
        padding-top: 7px;
        padding-left: 21px;

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          margin: auto;
          left: 3px;
          top: 7px;
          bottom: 0;
          background-repeat: no-repeat;
          background-size: contain;
          transform: translateZ(0);
        }
      }

      &-location {
        &::before {
          background-position: 1px;
          width: 12px;
          height: 15px;
          background-image: url('/static/images/residences/icon-location.svg');
        }
      }

      &-deadline {
        &::before {
          background-position: 1px;
          width: 14px;
          height: 9px;
          background-image: url('/static/images/residences/icon-key.svg');
        }
      }
    }
  }
}

/* FixMe: do it with mixins for media queries */
@media screen and (min-width: 1023px) {
  @include item-card(16%, 0.66%, 6);
}

@media screen and (min-width: 864px) and (max-width: 1022px) {
  @include item-card(19%, 1.2%, 5);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 6) {
      display: none;
    }
  }
}

@media screen and (min-width: 688px) and (max-width: 863px) {
  @include item-card(24%, 1.3%, 4);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 5) {
      display: none;
    }
  }
}

@media screen and (min-width: 520px) and (max-width: 687px) {
  @include item-card(32.5%, 1.2%, 3);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 4) {
      display: none;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 519px) {
  @include item-card(48.5%, 2.9%, 2);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 3) {
      display: none;
    }
  }
}

@media screen and (min-width: 316px) and (max-width: 374px) {
  @include item-card(48.5%, 1%, 2);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 3) {
      display: none;
    }
  }
}

@media screen and (max-width: 315px) {
  .item-card {
    width: 100%;
  }
}
